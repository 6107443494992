exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-about-html-js": () => import("./../../../src/pages/account/about.html.js" /* webpackChunkName: "component---src-pages-account-about-html-js" */),
  "component---src-pages-account-manager-html-js": () => import("./../../../src/pages/account/manager.html.js" /* webpackChunkName: "component---src-pages-account-manager-html-js" */),
  "component---src-pages-account-noti-html-js": () => import("./../../../src/pages/account/noti.html.js" /* webpackChunkName: "component---src-pages-account-noti-html-js" */),
  "component---src-pages-account-orderdetail-html-js": () => import("./../../../src/pages/account/orderdetail.html.js" /* webpackChunkName: "component---src-pages-account-orderdetail-html-js" */),
  "component---src-pages-account-orders-html-js": () => import("./../../../src/pages/account/orders.html.js" /* webpackChunkName: "component---src-pages-account-orders-html-js" */),
  "component---src-pages-account-personalized-html-js": () => import("./../../../src/pages/account/personalized.html.js" /* webpackChunkName: "component---src-pages-account-personalized-html-js" */),
  "component---src-pages-account-police-html-js": () => import("./../../../src/pages/account/police.html.js" /* webpackChunkName: "component---src-pages-account-police-html-js" */),
  "component---src-pages-account-service-html-js": () => import("./../../../src/pages/account/service.html.js" /* webpackChunkName: "component---src-pages-account-service-html-js" */),
  "component---src-pages-account-support-html-js": () => import("./../../../src/pages/account/support.html.js" /* webpackChunkName: "component---src-pages-account-support-html-js" */),
  "component---src-pages-api-checkapi-js": () => import("./../../../src/pages/api/checkapi.js" /* webpackChunkName: "component---src-pages-api-checkapi-js" */),
  "component---src-pages-bao-mat-thong-tin-ca-nhan-html-js": () => import("./../../../src/pages/bao-mat-thong-tin-ca-nhan.html.js" /* webpackChunkName: "component---src-pages-bao-mat-thong-tin-ca-nhan-html-js" */),
  "component---src-pages-cart-html-js": () => import("./../../../src/pages/cart.html.js" /* webpackChunkName: "component---src-pages-cart-html-js" */),
  "component---src-pages-chinh-sach-bao-hanh-html-js": () => import("./../../../src/pages/chinh-sach-bao-hanh.html.js" /* webpackChunkName: "component---src-pages-chinh-sach-bao-hanh-html-js" */),
  "component---src-pages-chinh-sach-doi-hang-html-js": () => import("./../../../src/pages/chinh-sach-doi-hang.html.js" /* webpackChunkName: "component---src-pages-chinh-sach-doi-hang-html-js" */),
  "component---src-pages-chinh-sach-uu-dai-doc-quyen-danh-tang-khach-hang-thanh-vien-2022-html-js": () => import("./../../../src/pages/chinh-sach-uu-dai-doc-quyen-danh-tang-khach-hang-thanh-vien-2022.html.js" /* webpackChunkName: "component---src-pages-chinh-sach-uu-dai-doc-quyen-danh-tang-khach-hang-thanh-vien-2022-html-js" */),
  "component---src-pages-chuong-trinh-tiep-thi-lien-ket-bibomart-html-js": () => import("./../../../src/pages/chuong-trinh-tiep-thi-lien-ket-bibomart.html.js" /* webpackChunkName: "component---src-pages-chuong-trinh-tiep-thi-lien-ket-bibomart-html-js" */),
  "component---src-pages-complete-order-html-js": () => import("./../../../src/pages/complete-order.html.js" /* webpackChunkName: "component---src-pages-complete-order-html-js" */),
  "component---src-pages-dac-quyen-html-js": () => import("./../../../src/pages/dac-quyen.html.js" /* webpackChunkName: "component---src-pages-dac-quyen-html-js" */),
  "component---src-pages-dang-nhap-html-js": () => import("./../../../src/pages/dang-nhap.html.js" /* webpackChunkName: "component---src-pages-dang-nhap-html-js" */),
  "component---src-pages-danh-muc-html-js": () => import("./../../../src/pages/danh-muc.html.js" /* webpackChunkName: "component---src-pages-danh-muc-html-js" */),
  "component---src-pages-dem-xu-ngay-doi-qua-lien-tay-html-js": () => import("./../../../src/pages/dem-xu-ngay-doi-qua-lien-tay.html.js" /* webpackChunkName: "component---src-pages-dem-xu-ngay-doi-qua-lien-tay-html-js" */),
  "component---src-pages-detailproduct-html-js": () => import("./../../../src/pages/detailproduct.html.js" /* webpackChunkName: "component---src-pages-detailproduct-html-js" */),
  "component---src-pages-dong-gia-html-js": () => import("./../../../src/pages/dong-gia.html.js" /* webpackChunkName: "component---src-pages-dong-gia-html-js" */),
  "component---src-pages-giam-gia-html-js": () => import("./../../../src/pages/giam-gia.html.js" /* webpackChunkName: "component---src-pages-giam-gia-html-js" */),
  "component---src-pages-gioi-thieu-bibomart-html-js": () => import("./../../../src/pages/gioi-thieu-bibomart.html.js" /* webpackChunkName: "component---src-pages-gioi-thieu-bibomart-html-js" */),
  "component---src-pages-googlebad-0-e-97-f-25-e-5-e-282-html-js": () => import("./../../../src/pages/googlebad0e97f25e5e282.html.js" /* webpackChunkName: "component---src-pages-googlebad-0-e-97-f-25-e-5-e-282-html-js" */),
  "component---src-pages-googlec-5492-db-9-c-87222-c-0-html-js": () => import("./../../../src/pages/googlec5492db9c87222c0.html.js" /* webpackChunkName: "component---src-pages-googlec-5492-db-9-c-87222-c-0-html-js" */),
  "component---src-pages-hang-moi-ve-html-js": () => import("./../../../src/pages/hang-moi-ve.html.js" /* webpackChunkName: "component---src-pages-hang-moi-ve-html-js" */),
  "component---src-pages-he-thong-cua-hang-html-js": () => import("./../../../src/pages/he-thong-cua-hang.html.js" /* webpackChunkName: "component---src-pages-he-thong-cua-hang-html-js" */),
  "component---src-pages-hot-deal-html-js": () => import("./../../../src/pages/hot-deal.html.js" /* webpackChunkName: "component---src-pages-hot-deal-html-js" */),
  "component---src-pages-html-js": () => import("./../../../src/pages/[...].html.js" /* webpackChunkName: "component---src-pages-html-js" */),
  "component---src-pages-huong-dan-tra-cuu-hoa-don-dien-tu-html-js": () => import("./../../../src/pages/huong-dan-tra-cuu-hoa-don-dien-tu.html.js" /* webpackChunkName: "component---src-pages-huong-dan-tra-cuu-hoa-don-dien-tu-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ket-qua-tim-kiem-html-js": () => import("./../../../src/pages/ket-qua-tim-kiem.html.js" /* webpackChunkName: "component---src-pages-ket-qua-tim-kiem-html-js" */),
  "component---src-pages-lien-he-bibomart-html-js": () => import("./../../../src/pages/lien-he-bibomart.html.js" /* webpackChunkName: "component---src-pages-lien-he-bibomart-html-js" */),
  "component---src-pages-live-html-js": () => import("./../../../src/pages/live.html.js" /* webpackChunkName: "component---src-pages-live-html-js" */),
  "component---src-pages-mobile-404-html-js": () => import("./../../../src/pages/mobile/404.html.js" /* webpackChunkName: "component---src-pages-mobile-404-html-js" */),
  "component---src-pages-mobile-address-detail-html-js": () => import("./../../../src/pages/mobile/address-detail.html.js" /* webpackChunkName: "component---src-pages-mobile-address-detail-html-js" */),
  "component---src-pages-mobile-address-html-js": () => import("./../../../src/pages/mobile/address.html.js" /* webpackChunkName: "component---src-pages-mobile-address-html-js" */),
  "component---src-pages-mobile-bixu-policy-html-js": () => import("./../../../src/pages/mobile/bixu-policy.html.js" /* webpackChunkName: "component---src-pages-mobile-bixu-policy-html-js" */),
  "component---src-pages-mobile-cart-html-js": () => import("./../../../src/pages/mobile/cart.html.js" /* webpackChunkName: "component---src-pages-mobile-cart-html-js" */),
  "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-chon-mau-sac-kich-co-html-js": () => import("./../../../src/pages/mobile/chi-tiet-mua-hang-tang-qua/chon-mau-sac-kich-co.html.js" /* webpackChunkName: "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-chon-mau-sac-kich-co-html-js" */),
  "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-danh-sach-html-js": () => import("./../../../src/pages/mobile/chi-tiet-mua-hang-tang-qua/danh-sach.html.js" /* webpackChunkName: "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-danh-sach-html-js" */),
  "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-danh-sach-qua-html-js": () => import("./../../../src/pages/mobile/chi-tiet-mua-hang-tang-qua/danh-sach-qua.html.js" /* webpackChunkName: "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-danh-sach-qua-html-js" */),
  "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-html-js": () => import("./../../../src/pages/mobile/chi-tiet-mua-hang-tang-qua/[...].html.js" /* webpackChunkName: "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-html-js" */),
  "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-xac-nhan-qua-html-js": () => import("./../../../src/pages/mobile/chi-tiet-mua-hang-tang-qua/xac-nhan-qua.html.js" /* webpackChunkName: "component---src-pages-mobile-chi-tiet-mua-hang-tang-qua-xac-nhan-qua-html-js" */),
  "component---src-pages-mobile-chinh-sach-doi-hang-html-js": () => import("./../../../src/pages/mobile/chinh-sach-doi-hang.html.js" /* webpackChunkName: "component---src-pages-mobile-chinh-sach-doi-hang-html-js" */),
  "component---src-pages-mobile-complete-order-html-js": () => import("./../../../src/pages/mobile/complete-order.html.js" /* webpackChunkName: "component---src-pages-mobile-complete-order-html-js" */),
  "component---src-pages-mobile-cua-hang-gan-ban-html-js": () => import("./../../../src/pages/mobile/cua-hang-gan-ban.html.js" /* webpackChunkName: "component---src-pages-mobile-cua-hang-gan-ban-html-js" */),
  "component---src-pages-mobile-danh-muc-html-js": () => import("./../../../src/pages/mobile/danh-muc.html.js" /* webpackChunkName: "component---src-pages-mobile-danh-muc-html-js" */),
  "component---src-pages-mobile-danh-sach-san-pham-html-js": () => import("./../../../src/pages/mobile/danh-sach-san-pham.html.js" /* webpackChunkName: "component---src-pages-mobile-danh-sach-san-pham-html-js" */),
  "component---src-pages-mobile-dong-gia-html-js": () => import("./../../../src/pages/mobile/dong-gia.html.js" /* webpackChunkName: "component---src-pages-mobile-dong-gia-html-js" */),
  "component---src-pages-mobile-giam-gia-html-js": () => import("./../../../src/pages/mobile/giam-gia.html.js" /* webpackChunkName: "component---src-pages-mobile-giam-gia-html-js" */),
  "component---src-pages-mobile-goi-y-mua-cung-html-js": () => import("./../../../src/pages/mobile/goi-y-mua-cung.html.js" /* webpackChunkName: "component---src-pages-mobile-goi-y-mua-cung-html-js" */),
  "component---src-pages-mobile-hang-moi-ve-html-js": () => import("./../../../src/pages/mobile/hang-moi-ve.html.js" /* webpackChunkName: "component---src-pages-mobile-hang-moi-ve-html-js" */),
  "component---src-pages-mobile-he-thong-cua-hang-html-js": () => import("./../../../src/pages/mobile/he-thong-cua-hang.html.js" /* webpackChunkName: "component---src-pages-mobile-he-thong-cua-hang-html-js" */),
  "component---src-pages-mobile-hot-deal-html-js": () => import("./../../../src/pages/mobile/hot-deal.html.js" /* webpackChunkName: "component---src-pages-mobile-hot-deal-html-js" */),
  "component---src-pages-mobile-html-js": () => import("./../../../src/pages/mobile/[...].html.js" /* webpackChunkName: "component---src-pages-mobile-html-js" */),
  "component---src-pages-mobile-lien-he-html-js": () => import("./../../../src/pages/mobile/lien-he.html.js" /* webpackChunkName: "component---src-pages-mobile-lien-he-html-js" */),
  "component---src-pages-mobile-login-html-js": () => import("./../../../src/pages/mobile/login.html.js" /* webpackChunkName: "component---src-pages-mobile-login-html-js" */),
  "component---src-pages-mobile-manager-html-js": () => import("./../../../src/pages/mobile/manager.html.js" /* webpackChunkName: "component---src-pages-mobile-manager-html-js" */),
  "component---src-pages-mobile-mua-hang-tang-qua-html-js": () => import("./../../../src/pages/mobile/mua-hang-tang-qua.html.js" /* webpackChunkName: "component---src-pages-mobile-mua-hang-tang-qua-html-js" */),
  "component---src-pages-mobile-noti-detail-html-js": () => import("./../../../src/pages/mobile/noti-detail.html.js" /* webpackChunkName: "component---src-pages-mobile-noti-detail-html-js" */),
  "component---src-pages-mobile-noti-html-js": () => import("./../../../src/pages/mobile/noti.html.js" /* webpackChunkName: "component---src-pages-mobile-noti-html-js" */),
  "component---src-pages-mobile-order-progress-html-js": () => import("./../../../src/pages/mobile/order-progress.html.js" /* webpackChunkName: "component---src-pages-mobile-order-progress-html-js" */),
  "component---src-pages-mobile-orderdetail-html-js": () => import("./../../../src/pages/mobile/orderdetail.html.js" /* webpackChunkName: "component---src-pages-mobile-orderdetail-html-js" */),
  "component---src-pages-mobile-orders-html-js": () => import("./../../../src/pages/mobile/orders.html.js" /* webpackChunkName: "component---src-pages-mobile-orders-html-js" */),
  "component---src-pages-mobile-page-landing-page-html-js": () => import("./../../../src/pages/mobile/page/landing-page.html.js" /* webpackChunkName: "component---src-pages-mobile-page-landing-page-html-js" */),
  "component---src-pages-mobile-personalized-html-js": () => import("./../../../src/pages/mobile/personalized.html.js" /* webpackChunkName: "component---src-pages-mobile-personalized-html-js" */),
  "component---src-pages-mobile-policy-regulations-html-js": () => import("./../../../src/pages/mobile/policy-regulations.html.js" /* webpackChunkName: "component---src-pages-mobile-policy-regulations-html-js" */),
  "component---src-pages-mobile-privacy-policy-html-js": () => import("./../../../src/pages/mobile/privacy-policy.html.js" /* webpackChunkName: "component---src-pages-mobile-privacy-policy-html-js" */),
  "component---src-pages-mobile-regulation-bixu-html-js": () => import("./../../../src/pages/mobile/regulation-bixu.html.js" /* webpackChunkName: "component---src-pages-mobile-regulation-bixu-html-js" */),
  "component---src-pages-mobile-regulation-html-js": () => import("./../../../src/pages/mobile/regulation.html.js" /* webpackChunkName: "component---src-pages-mobile-regulation-html-js" */),
  "component---src-pages-mobile-san-pham-html-js": () => import("./../../../src/pages/mobile/san-pham.html.js" /* webpackChunkName: "component---src-pages-mobile-san-pham-html-js" */),
  "component---src-pages-mobile-san-pham-tuong-tu-html-js": () => import("./../../../src/pages/mobile/san-pham-tuong-tu.html.js" /* webpackChunkName: "component---src-pages-mobile-san-pham-tuong-tu-html-js" */),
  "component---src-pages-mobile-san-xu-html-js": () => import("./../../../src/pages/mobile/san-xu.html.js" /* webpackChunkName: "component---src-pages-mobile-san-xu-html-js" */),
  "component---src-pages-mobile-terms-of-user-html-js": () => import("./../../../src/pages/mobile/terms-of-user.html.js" /* webpackChunkName: "component---src-pages-mobile-terms-of-user-html-js" */),
  "component---src-pages-mobile-them-ban-them-qua-html-js": () => import("./../../../src/pages/mobile/them-ban-them-qua.html.js" /* webpackChunkName: "component---src-pages-mobile-them-ban-them-qua-html-js" */),
  "component---src-pages-mobile-tim-cua-hang-html-js": () => import("./../../../src/pages/mobile/tim-cua-hang.html.js" /* webpackChunkName: "component---src-pages-mobile-tim-cua-hang-html-js" */),
  "component---src-pages-mobile-tim-kiem-html-js": () => import("./../../../src/pages/mobile/tim-kiem.html.js" /* webpackChunkName: "component---src-pages-mobile-tim-kiem-html-js" */),
  "component---src-pages-mobile-trang-chu-html-js": () => import("./../../../src/pages/mobile/trang-chu.html.js" /* webpackChunkName: "component---src-pages-mobile-trang-chu-html-js" */),
  "component---src-pages-mobile-user-info-html-js": () => import("./../../../src/pages/mobile/user-info.html.js" /* webpackChunkName: "component---src-pages-mobile-user-info-html-js" */),
  "component---src-pages-mobile-warranty-policy-html-js": () => import("./../../../src/pages/mobile/warranty-policy.html.js" /* webpackChunkName: "component---src-pages-mobile-warranty-policy-html-js" */),
  "component---src-pages-mobile-wishlist-html-js": () => import("./../../../src/pages/mobile/wishlist.html.js" /* webpackChunkName: "component---src-pages-mobile-wishlist-html-js" */),
  "component---src-pages-mua-hang-tang-qua-html-js": () => import("./../../../src/pages/mua-hang-tang-qua.html.js" /* webpackChunkName: "component---src-pages-mua-hang-tang-qua-html-js" */),
  "component---src-pages-noti-html-js": () => import("./../../../src/pages/noti.html.js" /* webpackChunkName: "component---src-pages-noti-html-js" */),
  "component---src-pages-orderingguide-html-js": () => import("./../../../src/pages/orderingguide.html.js" /* webpackChunkName: "component---src-pages-orderingguide-html-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-landing-page-html-js": () => import("./../../../src/pages/page/landing-page.html.js" /* webpackChunkName: "component---src-pages-page-landing-page-html-js" */),
  "component---src-pages-phuong-thuc-thanh-toan-html-js": () => import("./../../../src/pages/phuong-thuc-thanh-toan.html.js" /* webpackChunkName: "component---src-pages-phuong-thuc-thanh-toan-html-js" */),
  "component---src-pages-quy-dinh-chung-html-js": () => import("./../../../src/pages/quy-dinh-chung.html.js" /* webpackChunkName: "component---src-pages-quy-dinh-chung-html-js" */),
  "component---src-pages-register-html-js": () => import("./../../../src/pages/register.html.js" /* webpackChunkName: "component---src-pages-register-html-js" */),
  "component---src-pages-san-pham-html-js": () => import("./../../../src/pages/san-pham.html.js" /* webpackChunkName: "component---src-pages-san-pham-html-js" */),
  "component---src-pages-san-xu-html-js": () => import("./../../../src/pages/san-xu.html.js" /* webpackChunkName: "component---src-pages-san-xu-html-js" */),
  "component---src-pages-su-dung-thong-tin-html-js": () => import("./../../../src/pages/su-dung-thong-tin.html.js" /* webpackChunkName: "component---src-pages-su-dung-thong-tin-html-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

